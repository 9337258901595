import { faBars, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./index.css"
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function IconePrioridade({ prioridade }) {
    return (
        <OverlayTrigger
            key="buttonEditarAnotacao"
            placement="top"
            overlay={
                <Tooltip id={`tooltip-prioridade`}>
                    {prioridade}
                </Tooltip>
            }
        >
          <div className="item-icone">
              {prioridade ? (
                  prioridade === "Muito Baixa" ? (
                      <div
                          className="icone-prioridade"
                          data-tip={"Prioridade " + prioridade}
                      >
                          <FontAwesomeIcon color="#3D56B2" icon={faChevronDown} className="iconDouble" />
                          <FontAwesomeIcon color="#3D56B2" icon={faChevronDown} />
                      </div>
                  ) : prioridade === "Baixa" ? (
                      <div
                          className="icone-prioridade"
                          data-tip={"Prioridade " + prioridade}
                      >
                          <FontAwesomeIcon color="#3D56B2" icon={faChevronDown} />
                      </div>
                  ) : prioridade === "Média" ? (
                      <div
                          className="icone-prioridade"
                          data-tip={"Prioridade " + prioridade}
                      >
                          <FontAwesomeIcon color="#FFAB00" icon={faBars} />
                      </div>
                  ) : prioridade === "Alta" ? (
                      <div
                          className="icone-prioridade"
                          data-tip={"Prioridade " + prioridade}
                      >
                          <FontAwesomeIcon color="#FF5C58" icon={faChevronUp} />
                      </div>
                  ) : prioridade === "Urgência" ? (
                      <div
                          className="DoubleArrow"
                          data-tip={"Prioridade " + prioridade}
                      >
                          <FontAwesomeIcon color="#FF5C58" icon={faChevronUp} className="iconDouble" />
                          <FontAwesomeIcon color="#FF5C58" icon={faChevronUp} />
                      </div>
                  ) : (
                      <></>
                  )
              ) : (
                  <></>
              )}
          </div>
        </OverlayTrigger>
    );
}
