import React from 'react';
import { faWhatsapp, faFacebookMessenger, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faBullhorn, faComment, faEnvelope, faHandPaper, faPhoneAlt, faSms, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

export default function MidiaSocialIcone({ midia, contatoOrigem }) {
    const midiaMap = {
        whatsapp: {
            icone: faWhatsapp,
            descricao: 'whatsapp',
            cor: '#fff',
            background: '#00a859',
        },
        sms: {
            icone: faSms,
            descricao: 'sms',
            cor: '#fff',
            background: '#353252',
        },
        voip: {
            icone: faPhoneAlt,
            descricao: 'voip',
            cor: '#ffff',
            background: '#f7941d',
        },
        email: {
            icone: faEnvelope,
            descricao: 'email',
            cor: '#fff',
            background: '#F44336',
        },
        video: {
            descricao: 'video',
            icone: faVideo,
            cor: '#fff',
            background: '#4d4d4d',
        },
        chatbot: {
            descricao: 'chatbot',
            icone: faComment,
            cor: '#fff',
            background: '#3689ff',
        },
        reclame_aqui: {
            descricao: 'Reclame Aqui',
            icone: faBullhorn,
            cor: '#007535',
            background: '#90B823'
        },
        facebook_messenger: {
            descricao: 'Facebook Messenger',
            icone: faFacebookMessenger,
            cor: '#0099F2',
            background: '#eee'
        },
        instagram: {
            descricao: 'Instagram',
            icone: faInstagram,
            cor: '#fff',
            background: '#D92E76'
        },
        cadastro_manual: {
            descricao: "Cadastro Manual",
            icone: faHandPaper,
            cor: '#fff',
            background: '#cccccc'
        }
    }
    let midiaSelecionada = midiaMap[midia];
    if(midia === 'reclame-aqui') {
        midiaSelecionada = midiaMap['reclame_aqui'];
    } else if(midia === 'facebook-messenger') {
        midiaSelecionada = midiaMap['facebook_messenger'];
    }

    if (!midiaSelecionada) {
        midiaSelecionada = {
            icone: faBullhorn,
            descricao: 'DEFAULT',
            cor: 'grey',
        };
    }



    const icon = midiaSelecionada.icone;
    const color = midiaSelecionada.cor;
    const description = contatoOrigem || midiaSelecionada.descricao;
    const background = midiaSelecionada.background;

    return (
        <>
            <ReactTooltip />
            <div className="registro-col icon" style={{ backgroundColor: background }}>
                <FontAwesomeIcon
                    data-tip={description}
                    icon={icon}
                    color={color}
                />
            </div>
        </>
    )
}
