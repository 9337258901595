import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import caAPI from "../../../../../services/caAPI";
import Swal from "sweetalert2";
import { Alert, Spinner } from "react-bootstrap";
import { CardChamado } from "../../../../../components/CardChamado";
import { trackPromise } from "react-promise-tracker";
import { successToast } from "../../../../../components/DommusToast";

export function ChamadosVinculados({idAtendimento}){
  const [loading, setLoading] = useState(false);
  const [listaChamados, setListaChamados] = useState([]);

  useEffect(()=>{
    buscarChamadosVinculados();
  },[])

  const buscarChamadosVinculados = ()=>{
    setLoading(true);
    caAPI.atendimento.buscarChamadosVinculados(idAtendimento).then((response)=>{
      setListaChamados(response.data);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao carregar lista de chamados vinculados: " + error,
          icon: 'error'
      });    
    })
  }

  const handleDesvincularChamado = (chamado) => {
    Swal.fire({
      titleText: `Desvincular Chamado?`,
      text: `Tem certeza que deseja desvincular o chamado ${chamado?.protocolo}?`,
      icon: "question",
      showCloseButton: false,
      showDenyButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim!",
      denyButtonText: "Não!",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-secondary mr-3",
        denyButton: "btn btn-danger mr-3",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        trackPromise(caAPI.atendimento.desvincularChamado(idAtendimento, chamado.id_ticket)).then((response)=>{
          setListaChamados((current)=> current.filter((item) => item.id_ticket !== chamado.id_ticket));
          successToast.fire("Chamado desvinculado com sucesso!");
        }).catch((error)=>{
          let message = error?.response?.data?.message ?? error;
          Swal.fire({
              titleText: "Erro",
              text: "Houve um problema ao desvincular o chamado: " + message,
              icon: 'error'
          });    
        });
      }
    });
  }

  return (
    <div className="chamados-vinculados-section">
      {loading ?
          <Alert variant="info"><Spinner animation="border" size="sm"/> <span className="ml-1">Carregando ...</span></Alert>
        :
        (listaChamados.length ? 
            listaChamados.map((item, index)=>{
              return (<CardChamado chamado={item} key={index} handleDesvincularChamado={handleDesvincularChamado}/>)
            })
        :
        <Alert variant="warning">Nenhum chamado vinculado</Alert>)
      }
    </div>
  )
}
