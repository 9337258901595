import React, { useEffect, useState } from "react";
import "./index.css";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import caAPI from "../../../../../services/caAPI";
import { Alert } from "react-bootstrap";
import { CardChamado } from "../../../../../components/CardChamado";
import { successToast } from "../../../../../components/DommusToast";

export function VinculoChamado({atendimento}){
  const [listaChamados, setListaChamados] = useState([]);

  useEffect(()=>{
    trackPromise(caAPI.atendimento.buscarChamadosVinculo(atendimento.id, atendimento.processo)).then((response)=>{
      setListaChamados(response.data);
    }).catch((error)=>{
      Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao carregar lista de chamados: " + error,
          icon: 'error'
      });
    })
  },[])

  const handleVincularChamadoAtendimento = (chamado) => {
    Swal.fire({
      titleText: `Vincular Chamado?`,
      text: `Tem certeza que deseja vincular o chamado ${chamado?.protocolo}?`,
      icon: "question",
      showCloseButton: false,
      showDenyButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim!",
      denyButtonText: "Não!",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-secondary mr-3",
        denyButton: "btn btn-danger mr-3",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        trackPromise(caAPI.atendimento.vincularChamado(atendimento.id, chamado.id_ticket)).then((response)=>{
            setListaChamados((current)=>{
              return current.filter((item) => item.id_ticket !== chamado.id_ticket);
            })
            successToast.fire("Chamado vinculado com sucesso!");
        }).catch((error)=>{
          let message = error?.response?.data?.message ?? error;
          Swal.fire({
             titleText: "Erro",
             text: "Houve um problema ao vincular chamado ao atendimento:" + message,
             icon: 'error'
          })
        })
      }
    })
  }

  return ( 
    <div className="lista-cards-chamados-modal">
      {listaChamados.length ? 
        listaChamados.map((item, index)=>{
          return(
            <CardChamado chamado={item} key={index} handleVincularChamado={handleVincularChamadoAtendimento}/>
          )
        })
        : 
      <Alert variant="warning">Nenhum chamado encontrado para vínculo</Alert>}
    </div>
  )
}
