import React, { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { Form, Row, Card, CardGroup, Col, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { trackPromise } from "react-promise-tracker";
import "./ModalNovoTemplate.scss";
import caAPI from "../../../services/caAPI";
import DommusRequiredSpan from "../../../components/DommusRequiredSpan";
import MidiaSocialIcone from "../../../components/MidiaSocialIcone";
import {ModulosContext} from '../../../contexts/ModulosContext';
import {errorToast} from "../../../components/DommusToast";
import EditorDommusTags from "../../../components/EditorDommusTags";
import { TiposAnexosTemplateContext } from "../../../contexts/TiposAnexosTemplateContext";
import { MeioComunicacaoContext } from "../../../contexts/MeioComunicacaoContext";
import { TipoAtendimentoContext } from "../../../contexts/TipoAtendimentoContext";
import { AtendentesContext } from "../../../contexts/AtendentesContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faCloudArrowUp, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export default function ModalNovoTemplate({
  setOpenModalNovoTemplate,
  updateListaTemplates,
  idTemplate,
  handleAtualizaListaTemplates,
}) {

  const [valuesSelectTiposAtendimentos, setValuesSelectTiposAtendimentos] = useState([]);
  const [tiposAtendimentoSelecionado, setTiposAtendimentoSelecionado] = useState([]);
  const [nomeTemplateInput, setNomeTemplateInput] = useState("");
  const [linkTemplateInput, setLinkTemplateInput] = useState(null);
  const [mensagemInput, setMensagemInput] = useState("");
  const [selecionadosTemplate, setSelecionadosTemplate] = useState([]);
  const [podeEditar, setPodeEditar] = useState(true);
  const [dadosImagemTemplate, setDadosImagemTemplate] = useState({});
  const [aprovado, setAprovado] = useState(true);
  const [infoTemplateEditar, setInfoTemplateEditar] = useState({});
  const [modelosTags, setModelosTags] = useState([]);
  const [gruposTags, setGruposTags] = useState({});
  const [tiposTemplate, setTiposTemplate] = useState([]);
  const [tipoTemplateSelecionado, setTipoTemplateSelecionado] = useState("");
  const [moduloSelecionado, setModuloSelecionado] = useState("");
  const [modeloTagSelecionado, setModeloTagSelecionado] = useState("");
  const [tipoModeloImpressaoAnexoSelecionado, setTipoModeloImpressaoAnexoSelecionado] = useState("");
  const [modeloImpressaoAnexoSelecionado, setModeloImpressaoAnexoSelecionado] = useState("");
  const [documentosAnexoSelecionados, setDocumentosAnexoSelecionados] = useState([]);
  const {documentosCarregadoAnexos, modelosImpressaoAnexos, resolverNomeDocumentoCarregado} = useContext(TiposAnexosTemplateContext);
  const [assuntoEmail, setAssuntoEmail] = useState('')
  const [copiasEmail, setCopiasEmail] = useState('')
  const [copiasOcultasEmail, setCopiasOcultasEmail] = useState('')
  const [options, setOptions] = useState([]);
  const {modulos} = useContext(ModulosContext);
  const {meiosComunicacao} = useContext(MeioComunicacaoContext);
  const {tiposAtendimento} = useContext(TipoAtendimentoContext);
  const {listaAtendentes} = useContext(AtendentesContext);
  const [atendentes, setAtendentes] = useState([]);
  const [valuesSelectAtendentes, setValuesSelectAtendentes] = useState([]);
  const [usaSpanEditor, setUsaSpanEditor] = useState(true);
  const [templateSid, setTemplateSid] = useState('')
  const [erroTemplateSid, setErroTemplateSid] = useState(null)
  const [listaModelos, setListaModelos] = useState({})
  const [accountSid, setAccountSid] = useState(null)
  
  const animatedComponents = makeAnimated();

  const handleSelectMeioComunicacao = (midia) => {
    let novoUsaSpan = usaSpanEditor;
    if (!podeEditar) {
      return;
    }
    let value = [...selecionadosTemplate];
    if (selecionadosTemplate.filter((item) => item === midia.id).length) {
      const index = selecionadosTemplate.findIndex((item) => item === midia.id);
      value.splice(index, 1);
      if(midia.slug === 'whatsapp' && !(novoUsaSpan)){
        novoUsaSpan = true;
      }
    } else {
      value.push(midia.id);
      if(midia.slug === 'whatsapp' && (novoUsaSpan)){
        novoUsaSpan = false;
      }
    }
    setSelecionadosTemplate(value);
    setUsaSpanEditor(novoUsaSpan);
  };

  const handleMontarArquivo = function (event) {
    if (event.target.files[0]) {
      let separaTipo = event.target.files[0].type.split("/").pop().split(".");
      let reader = new FileReader();
      let file = event.target.files[0];
      reader.onload = function (upload) {
        let dadosArquivo = {
          conteudo: upload.target.result.split(/base64,/)[1],
          nome: file.name,
          tipo: separaTipo[separaTipo.length - 1],
        };
        setDadosImagemTemplate(dadosArquivo);
      };
      file && reader.readAsDataURL(file);
    }
  };

  const optionsDocumentosAnexo = documentosCarregadoAnexos?.map((documento)=>{
      return {
      value: documento.id,
      label: documento.descricao
    }
  });

  const optionsAtendentes = useMemo(()=>{
    return(
      listaAtendentes.map((atendente)=>{
        return {
          value: atendente.id,
          label: atendente.nome,
        }
      })
    )
  },[listaAtendentes])


  useEffect(() => {
    if (selecionadosTemplate.includes(1)) {
      setTiposTemplate(["Texto"]);
      setDadosImagemTemplate({});
      setTipoTemplateSelecionado("Texto");
      return;
    }

    if (selecionadosTemplate.includes(2) || selecionadosTemplate.includes(4)) {
      setTiposTemplate(["Texto", "Texto e Imagem"]);
    }

    if (selecionadosTemplate.length === 0) {
      setTiposTemplate([]);
      setDadosImagemTemplate({});
      setTipoTemplateSelecionado("");
    }
  }, [selecionadosTemplate]);

  useEffect(() => {
    let value = [];
    tiposAtendimento &&
    tiposAtendimento?.map((tipo) =>
        value.push({
          value: `${tipo.id}`,
          label: `${tipo.descricao}`,
        })
      );
    setOptions(value);
  }, [tiposAtendimento, idTemplate]);

  const handleSubmitForm = function () {
    const meioComunicacaoEmailSelecionado = validarMeioComunicacaoSelecionadoPorSlug('email')
    if(meioComunicacaoEmailSelecionado && !assuntoEmail) {
      Swal.fire('Campos obrigatórios não preenchidos!', 'Preencha todos os campos obrigatórios para salvar o template.', 'warning')
      
      return
    }
    const template = {
      nome: nomeTemplateInput,
      tipo_atendimento: tiposAtendimentoSelecionado,
      atendentes: atendentes,
      meio_comunicacao: selecionadosTemplate,
      mensagem: mensagemInput,
      imagem: dadosImagemTemplate,
      tipo_template: tipoTemplateSelecionado,
      aprovado: aprovado ? "S" : "N",
      link: linkTemplateInput,
      tipoModeloImpressao: tipoModeloImpressaoAnexoSelecionado || null,
      modeloImpressao: modeloImpressaoAnexoSelecionado || null,
      documentoAnexo: documentosAnexoSelecionados?.map(documento => {
        return documento.value;
      }),
      id_modulo_tags: moduloSelecionado || null,
      id_modelo_tags: modeloTagSelecionado || null,
      email_assunto: meioComunicacaoEmailSelecionado ? assuntoEmail : null,
      emails_copia: meioComunicacaoEmailSelecionado ? copiasEmail : null,
      emails_copia_oculta: meioComunicacaoEmailSelecionado ? copiasOcultasEmail : null,
      template_sid: validarMeioComunicacaoSelecionadoPorSlug('whatsapp') && templateSid || null,
      account_sid: validarMeioComunicacaoSelecionadoPorSlug('whatsapp') && accountSid || null
    };
    caAPI.template
      .create(template, idTemplate)
      .then((res) => {
        updateListaTemplates(res.data);
        setOpenModalNovoTemplate(false);
      })
      .catch((err) => {
        Swal.fire({
          titleText: "Erro",
          text: "Preencha todos os campos obrigatórios",
          icon: "error",
        });
      })
      .finally(() => handleAtualizaListaTemplates());
  };

  const handleChangeTipoTemplate = function (value) {
    setTipoTemplateSelecionado(value);
  };

  useEffect(() => {
    if (idTemplate) {
      trackPromise(
        caAPI.template
          .buscaPorId(idTemplate)
          .then((res) => {
            const info = JSON.parse(res.data.info);
            setInfoTemplateEditar(info);
            setNomeTemplateInput(res.data.nome);
            setLinkTemplateInput(res.data.link);
            setAssuntoEmail(res.data.email_assunto || '')
            setCopiasEmail(res.data.emails_copia || '')
            setCopiasOcultasEmail(res.data.emails_copia_oculta || '')
            setValuesSelectTiposAtendimentos(
              res.data.tipos?.map((tipos) => ({
                value: tipos.id.toString(),
                label: tipos.descricao,
              }))
            );
            setTemplateSid(res.data.template_sid)
            setAccountSid(res.data.account_sid)
            if(res.data.atendentes && res.data.atendentes.length){
              let arrayAtendentes = [];
              listaAtendentes.forEach(atendente => {
                res.data.atendentes?.forEach(idAtendente => {
                  if (idAtendente === atendente.id) {
                    arrayAtendentes.push(atendente)
                  }
                })
              });
              setValuesSelectAtendentes(arrayAtendentes.map((atendente) => ({
                value: atendente.id,
                label: atendente.nome,
              })));
              setAtendentes(res.data.atendentes);
            }
            setSelecionadosTemplate([...res.data.meios.map((item) => item.id)]);
            setTiposAtendimentoSelecionado(res.data.tipos.map((tipo) => tipo.id));
            setMensagemInput(res.data.mensagem);
            setPodeEditar(res.data.permitido_editar);
            setAprovado(res.data.aprovado === "S" ? true : false);
            setTipoTemplateSelecionado(info ? info.tipo : null);
            setDocumentosAnexoSelecionados(JSON.parse(res.data?.id_anexos_documentos_carregados)?.map((id)=>{
              return {
                value: id,
                label: resolverNomeDocumentoCarregado(id)
              }
            }));
            setTipoModeloImpressaoAnexoSelecionado(res.data?.id_anexo_modelo_impressao);
            setModuloSelecionado(res.data?.id_modulo_tags?.toString() || "");
            handleChangeModulo(res.data?.id_modulo_tags?.toString());
            setModeloTagSelecionado(res.data?.id_modelo_tags?.toString() || "");
            handleChangeModeloTags(res.data?.id_modelo_tags, res.data?.id_modulo_tags);
            if(res.data?.id_anexo_modelo_impressao && res.data?.id_modulo_tags){
              handleChangeModelosImpressaoAnexo(res.data?.id_anexo_modelo_impressao, res.data?.id_modulo_tags);
              setModeloImpressaoAnexoSelecionado(res.data?.id_modelo_impressao_modelo);
            }
          })
          .catch((err) => {
            console.log(err)
            Swal.fire({
              titleText: "Erro",
              text: "Houve um problema ao buscar o Template",
              icon: "error",
            });
          })
      );
    }
  }, [idTemplate]);
  function handleEditorChange(content, editor) {
    content ? setMensagemInput(content) : setMensagemInput(" ");
  }

  function selecionaTipoAtendimento(event) {
    setTiposAtendimentoSelecionado([...event.map((value) => value.value)]);
    setValuesSelectTiposAtendimentos(event);
  }

  function selecionaDocumentosAnexos(event) {
    setDocumentosAnexoSelecionados(event);
  }

  function selecionaAtendente(event) {
    setAtendentes([...event.map((value) => value.value)]);
    setValuesSelectAtendentes(event);
  }

  const handleChangeModulo = (idModulo) => {
    if(idModulo){
      if(moduloSelecionado !== ""){
        const novoGruposTags = {};
        const novoModeloSelecionado = "";
        setGruposTags(novoGruposTags);
        setModeloTagSelecionado(novoModeloSelecionado);
      }
      setModuloSelecionado(idModulo);
      const meioEmail = meiosComunicacao.find((meio) => meio.slug === 'email');
      if(idModulo, meioEmail){
        trackPromise(caAPI.meio_comunicacao.buscarModelosModulo(meioEmail.id, idModulo).then((res) => {
          setModelosTags(res.data || []);
        }).catch(error => errorToast.fire({
          text: "Ocorreu um erro ao buscar os modelos de dado para o meio."
        })));
      }else{
        setModelosTags([]);
        setTipoModeloImpressaoAnexoSelecionado(0);
        setDocumentosAnexoSelecionados([]);
      }
    }else{
      setModuloSelecionado("");
      setModeloTagSelecionado("");
      setModelosTags([]);
      setTipoModeloImpressaoAnexoSelecionado(0);
      setDocumentosAnexoSelecionados([]);
    }

  }

  const handleChangeModeloTags = (idModelo, idModulo=null) => {
    setModeloTagSelecionado(idModelo);
    const meioEmail = meiosComunicacao.find((meio) => meio.slug === 'email');
    if(meioEmail,(moduloSelecionado || idModulo), idModelo){
      trackPromise(caAPI.meio_comunicacao.buscarTagsModelo(meioEmail.id, idModulo, idModelo).then(
        (res) => {
          const novasTags = Object.assign({}, res.data);
          setGruposTags(novasTags);
        }
      ).catch(error => errorToast.fire({
        text: "Ocorreu um erro ao buscar os modelos de dado para o meio."
      })));
    }else{
      const grupos = {};
      setGruposTags(grupos);
    }

  }

  const handleChangeModelosImpressaoAnexo = useCallback((tipoModelo, idModulo=null)=>{
    setTipoModeloImpressaoAnexoSelecionado(tipoModelo);
    if(tipoModelo){
      trackPromise(caAPI.meio_comunicacao.buscarListaModelosPorTipo( tipoModelo, idModulo).then(
        (res) => {
          const modelos = Object.assign({}, res.data);
          console.log(modelos)
          setListaModelos(modelos);
        }
      ).catch(error => errorToast.fire({
        text: "Ocorreu um erro ao buscar os modelos de dado para o meio."
      })));
    }else{
      const modelos = {};
      setListaModelos(modelos);
    }
  },[])

  const handleChangeModeloImpressaoAnexo = useCallback((tipoModelo, idModulo=null)=>{
    setModeloImpressaoAnexoSelecionado(tipoModelo);
  },[])

  

  function validarMeioComunicacaoSelecionadoPorSlug(slug) {
    return meiosComunicacao && selecionadosTemplate.includes(meiosComunicacao?.find(meioComunicacao => meioComunicacao.slug == slug)?.id || 0)
  }

  function validarSidTemplate(templateSid, accountSid = null) {
    trackPromise(caAPI.template.validarSidTemplate(idTemplate, templateSid, accountSid))
      .then(response => {
        setMensagemInput(response.data.mensagem)
        setErroTemplateSid(null)
      }).catch(error => {
        setErroTemplateSid('Template SID inválido.')
        console.log(error)
        console.log(error?.response)
      })
  }

  return (
    <>
      <Row className="mb-4" style={{ minHeight: '80vh' }}>
        <Col lg={5}>
          <fieldset>
            <legend>Dados do Template</legend>
            <div className="novoTemplate">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group as={Col}>
                      <Form.Label className="inputLabel mb-2">
                        Meio de Comunicação: <DommusRequiredSpan />
                      </Form.Label>
                      <Form.Control as="checkbox" custom is="x3d">
                        <CardGroup>
                          {meiosComunicacao &&
                            meiosComunicacao?.map((midia) => {
                              let isChecked = null;
                              if (idTemplate && selecionadosTemplate) {
                                isChecked = selecionadosTemplate.filter(
                                  (id) => id === midia.id
                                ).length;
                              }
                              return (
                                <div key={midia.id} style={{ width: '55px', marginRight: '30px' }}>
                                  <Card className="midiaCard">
                                    <Card.Body
                                      className="midiaCorpo"
                                      disabled={podeEditar}
                                    >
                                      <Form.Check
                                        className="midia px-0 pr-2"
                                        type="checkbox"
                                        is="x3d"
                                        id={midia.id}
                                        value={midia.id}
                                      >
                                        <Form.Check.Input
                                          checked={isChecked}
                                          type="checkbox"
                                          is="x3d"
                                        />
                                        <Form.Check.Label
                                          onClick={() => {
                                            handleSelectMeioComunicacao(midia);
                                          }}
                                        >
                                          <MidiaSocialIcone midia={midia.slug} />
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </Card.Body>
                                  </Card>
                                </div>
                              );
                            })}
                        </CardGroup>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group as={Col}>
                          <Form.Label className="inputLabel">
                            Nome do Template: <DommusRequiredSpan />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={nomeTemplateInput}
                            disabled={!podeEditar}
                            onChange={(event) =>
                              setNomeTemplateInput(event.target.value)
                            }
                            isDisabled={!podeEditar}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {validarMeioComunicacaoSelecionadoPorSlug('email') && (<>
                        <Row>
                          <Col>
                            <Form.Group as={Col}>
                              <Form.Label className="inputLabel">Assunto do Email <DommusRequiredSpan /></Form.Label>
                              <Form.Control type="text" value={assuntoEmail} onChange={(event) => setAssuntoEmail(event.target.value)} isDisabled={!podeEditar}/>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group as={Col}>
                              <Form.Label className="inputLabel">CC</Form.Label>
                              <Form.Control type="email" value={copiasEmail} onChange={(event) => setCopiasEmail(event.target.value)} isDisabled={!podeEditar}/>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group as={Col}>
                              <Form.Label className="inputLabel">CCO</Form.Label>
                              <Form.Control type="email" value={copiasOcultasEmail} onChange={(event) => setCopiasOcultasEmail(event.target.value)} isDisabled={!podeEditar}/>
                            </Form.Group>
                          </Col>
                        </Row>
                      </>) || <></>}
                    {validarMeioComunicacaoSelecionadoPorSlug('whatsapp') && (
                      <Row>
                        <Col>
                          <Form.Group as={Col}>
                            <Form.Label className="inputLabel">Account SID (Twillio):</Form.Label>
                            <Form.Control type="text" 
                              value={accountSid} 
                              onChange={(event) => setAccountSid(event.target.value)} 
                              isDisabled={!podeEditar} 
                              onBlur={(e) => templateSid && validarSidTemplate(templateSid, e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Col}>
                            <Form.Label className="inputLabel">Template SID (Twillio):</Form.Label>
                            <Form.Control type="text" 
                              value={templateSid} 
                              onChange={(event) => setTemplateSid(event.target.value)} 
                              isDisabled={!podeEditar} 
                              onBlur={(e) => validarSidTemplate(e.target.value, accountSid)}
                            />
                            {erroTemplateSid && <small className="form-text text-danger"><FontAwesomeIcon icon={faExclamationTriangle}/> {erroTemplateSid}</small>}
                          </Form.Group>
                        </Col>
                      </Row>
                    ) || <></>}
                    <Row>
                      <Col xl={6}>
                        <Form.Group as={Col} col={1} controlId="formAtendimento">
                          <Form.Label className="inputLabel">
                            Tipo de Atendimento:
                          </Form.Label>
                          {options !== undefined ? (
                            <Select
                              value={valuesSelectTiposAtendimentos}
                              options={options}
                              components={animatedComponents}
                              isDisabled={!podeEditar}
                              isMulti
                              name="colors"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              menuPosition={"absolute"}
                              onChange={(event) => selecionaTipoAtendimento(event)}
                            />
                          ) : (
                            <></>
                          )}
                        </Form.Group>
                      </Col>
                      <Col xl={6}>
                        <Form.Group as={Col} col={2} controlId="formAtendimento">
                          <Form.Label className="inputLabel">
                            Tipo de Template: <DommusRequiredSpan />
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={tipoTemplateSelecionado}
                            onChange={(event) =>
                              handleChangeTipoTemplate(event.target.value)
                            }
                            disabled={!podeEditar}
                          >
                            <option value="" disabled>
                              Selecione um Tipo de Template
                            </option>
                            {tiposTemplate?.map((tipoTemplate) => {
                              return (
                                <option key={tipoTemplate} value={tipoTemplate}>{tipoTemplate}</option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xl={6}>
                        <Form.Group as={Col} col={2} controlId="formAtendimento">
                          <Form.Label className="inputLabel">
                            Usar tags do Módulo:
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={moduloSelecionado}
                            onChange={(event) =>
                              handleChangeModulo(event.target.value)
                            }
                            disabled={!podeEditar}
                          >
                            <option value="">
                              Selecione um modulo para buscar as tags
                            </option>
                            {modulos?.map((modulo) => {
                              return (
                                <option key={modulo.id} value={modulo.id}>{modulo.nome}</option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xl={6}>
                        <Form.Group as={Col} col={1} controlId="formAtendimento">
                          <Form.Label className="inputLabel">
                            Atendentes:
                          </Form.Label>
                            <Select
                              value={valuesSelectAtendentes}
                              options={optionsAtendentes}
                              components={animatedComponents}
                              isDisabled={!podeEditar}
                              isMulti
                              name="colors"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              menuPosition={"absolute"}
                              onChange={(event) => selecionaAtendente(event)}
                            />
                        </Form.Group>
                      </Col>
                      {
                        moduloSelecionado && <Col xl={6}>
                          <Form.Group as={Col} col={2} controlId="formAtendimento">
                            <Form.Label className="inputLabel">
                              Tipo de Modelo:
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={modeloTagSelecionado}
                              onChange={(event) =>
                                handleChangeModeloTags(event.target.value, moduloSelecionado)
                              }
                              disabled={!podeEditar}
                              >
                              <option value="" hidden>
                                Selecione um modelo para buscar as tags
                              </option>
                              {modelosTags?.map((modelo) => {
                                return (
                                  <option key={modelo.id_tipo_modelo_contratual} value={modelo.id_tipo_modelo_contratual}>{modelo.descricao}</option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      }
                    </Row>
                    {
                      moduloSelecionado === "2" &&
                      <Row>
                        <Col xl={6}>
                          <Form.Group as={Col} col={2} controlId="formAtendimento">
                            <Form.Label className="inputLabel">
                              Tipo de Modelo para Anexo:
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={tipoModeloImpressaoAnexoSelecionado}
                              onChange={(event) =>
                                handleChangeModelosImpressaoAnexo(event.target.value, moduloSelecionado)
                              }
                              disabled={!podeEditar}
                              >
                              <option value="">
                                Selecione um anexo de impressão
                              </option>
                              {modelosImpressaoAnexos?.map((modelo) => {
                                return (
                                  <option key={modelo.id_tipo_modelo_contratual} value={modelo.id_tipo_modelo_contratual}>{modelo.descricao}</option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xl={6}>
                          <Form.Group as={Col} col={2} controlId="formAtendimento">
                            <Form.Label className="inputLabel">
                              Modelo para Anexo:
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={modeloImpressaoAnexoSelecionado}
                              onChange={(event) =>
                                handleChangeModeloImpressaoAnexo(event.target.value, moduloSelecionado)
                              }
                              disabled={!podeEditar}
                              >
                              <option value="">
                                Selecione um anexo de impressão
                              </option>
                              {Object.values(listaModelos)?.map((modelo) => {
                                return (
                                  <option key={modelo.id_modelo_contratual} value={modelo.id_modelo_contratual}>{modelo.titulo}</option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xl={6}>
                        <Form.Group as={Col} col={1} controlId="formAtendimento">
                          <Form.Label className="inputLabel">
                            Tipo de Documento para Anexo:
                          </Form.Label>
                          {optionsDocumentosAnexo !== undefined ? (
                            <Select
                              value={documentosAnexoSelecionados}
                              options={optionsDocumentosAnexo}
                              components={animatedComponents}
                              isMulti
                              name="colors"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              menuPosition={"absolute"}
                              onChange={(event) => selecionaDocumentosAnexos(event)}
                              isDisabled={!podeEditar}
                            />
                          ) : (
                            <></>
                          )}
                        </Form.Group>
                      </Col>
                      </Row>
                    }
                  </Col>
                </Row>
                {tipoTemplateSelecionado === "Texto e Imagem" && (
                  <Row className="mt-3">
                    <Col lg={12}>
                      <Form.Group>
                        <Form.Label className="inputLabel">
                          Anexar arquivo:
                        </Form.Label>
                        <Form.Control type={"file"}
                          id="formcheck-api-regular"
                          data-browse="Procurar"
                          onChange={(event) => handleMontarArquivo(event)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group>
                        <Form.Label className="inputLabel">
                          Link:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={linkTemplateInput}
                          disabled={!podeEditar}
                          onChange={(event) =>
                            setLinkTemplateInput(event.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                    {infoTemplateEditar.imagem?.url ? (
                      <img src={infoTemplateEditar.imagem.url} alt="" />
                    ) : (
                      ""
                    )}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <div>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label={<Form.Label className="inputLabel">&nbsp;Aprovado pelo Whatsapp</Form.Label>}
                          className={"disabled"}
                          onChange={(event) => setAprovado(event.target.value)}
                          value={aprovado}
                          is="x3d"
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </fieldset>
        </Col>
        <Col className="editorMensagem">
          <fieldset className="p-3">
              <legend>Mensagem</legend>
              <EditorDommusTags
                handleEditorChange={handleEditorChange}
                conteudo={mensagemInput}
                gruposTags={gruposTags}
                usarSpan={usaSpanEditor}
                disabled={!!templateSid}
              />
          </fieldset>
        </Col>
      </Row>
      <Row>
        <Col className="novoTemplate border-top">
          <div className="buttonsTemplate">
            <Button variant="secondary" className="submitTemplate fecharModal" type="button" onClick={() => setOpenModalNovoTemplate(false)}>
              <FontAwesomeIcon className="mr-1" icon={faArrowAltCircleLeft}/> Fechar
            </Button>
            <Button className="submitTemplate" onClick={handleSubmitForm} >
              <FontAwesomeIcon className="mr-1" icon={faCloudArrowUp} /> Salvar
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
