import React, { createContext, useMemo, useState } from "react";
import Swal from "sweetalert2";
import caAPI from "../services/caAPI";

export const ModulosContext = createContext({});

export function ModulosProvider({ children }) {
  const [modulos, setModulos] = useState([]);
  const possuiDesk = useMemo(()=>{
    let desk = modulos.find((item) => item.id === 9);
    return !!desk;
  },[modulos]);

  const recarregarModulos = ()=>{
    caAPI.modulo.buscar().then(res => {
        setModulos(res.data);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar os módulos contratados  " + err,
        icon: 'error'
    }))
  };


  return (
    <ModulosContext.Provider value={{
      modulos,
      recarregarModulos,
      possuiDesk
    }}>
      {children}
    </ModulosContext.Provider>
  )
}
