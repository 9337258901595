import { Icon } from '@iconify/react';
import React, { useContext } from 'react';
import { Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import overflowMenuHorizontal from '@iconify-icons/carbon/overflow-menu-horizontal';
import removeCircle from '@iconify-icons/el/remove-circle';
import ticketIcon from '@iconify-icons/entypo/ticket';
import Swal from 'sweetalert2';
import { AtendimentoContext } from '../../../contexts/AtendimentoContext';
import caAPI from "../../../services/caAPI";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { ModulosContext } from '../../../contexts/ModulosContext';

export default function MenuDetalhes ({tipoDetalhes, titleToggle, handleSetClienteDadosProcesso, handleSetClienteDadosOpv, handleSelecionaProcesso, link, handleVincularChamado, idProcesso}) {
  const { handleAtualizaLista,atendimentoAtivo } = useContext(AtendimentoContext);
  const {possuiDesk} = useContext(ModulosContext);

  function alteraProcesso() {
    Swal.fire({
      titleText: "Desvincular OPV/Processo do atendimento?",
      text: "Tem certeza que deseja desvincular essa OPV/Processo deste atendimento?",
      icon: 'question',
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      customClass: {
        confirmButton: 'btn btn-primary mr-3',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    }).then(
      (result) => {
        if (result.value) {
          if ( tipoDetalhes  === 'processo' )
          { 
              handleSetClienteDadosProcesso(null)
              handleDesvincularOPVProcesso('processo')
          }
          else
          { 
            handleSetClienteDadosOpv(null)
            handleDesvincularOPVProcesso('oportunidade');
          }
          handleSelecionaProcesso(null, tipoDetalhes);
        }
      }
    ).finally(() => {
      handleAtualizaLista()
    });
  }

    function handleDesvincularOPVProcesso(desvincular) {
        var data = {
            [desvincular]: null
        }
        caAPI.atendimento.update(data, atendimentoAtivo.id).then({
        }).catch(err => {
          console.log("err", err)
          Swal.fire({
              titleText: "Erro",
              text: "Erro ao Desvincular OPV/Processo",
              icon: 'error'
          });
      })
    }
    return <>
      <div className="numeroOPVProcesso">
        <OverlayTrigger key="abrirOpv" placement="top" overlay={<Tooltip id={`tooltip-abrir-opv`}>{`Abrir ${tipoDetalhes == 'processo'? 'Processo' : 'Oportunidade'}`}</Tooltip>}>
          <div className='text-dark' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => window.open(link, '_blank')}>
            <FontAwesomeIcon icon={faLink} color='#777' /> {titleToggle}
          </div>
        </OverlayTrigger>
      </div>
      <div className="menuOPVProcessos">
        <OverlayTrigger
          key="buttonMenuOPVProcessos"
          placement="top"
          overlay={
            <Tooltip id={`tooltip-editar`}>
              Menu
            </Tooltip>
          }
        >
          <DropdownButton
            id="DropOPVProcessos"
            size="sm"
            title={
              <Icon
                icon={overflowMenuHorizontal}
                width="2rem"
              // onClick={alteraProcesso}
              />
            }>
            <Dropdown.Item className="opcaoMenu" disabled href="#/action-2">
              {"Abrir chamado "}
              <Icon icon={ticketIcon} className="" />
            </Dropdown.Item>
            <Dropdown.Item className="opcaoMenu" onClick={alteraProcesso}>
              {"Desvincular OPV/Processo "}
              <Icon
                icon={removeCircle}
                width="1rem"
              // onClick={alteraProcesso}
              />
            </Dropdown.Item>
            {possuiDesk  && idProcesso ?
              <Dropdown.Item className="opcaoMenu" onClick={handleVincularChamado}>
                {"Vincular Chamado"}
                <FontAwesomeIcon
                  className='ml-2'
                  icon={faLink}
                />
              </Dropdown.Item>
            : <></>}
          </DropdownButton>
        </OverlayTrigger>
      </div>
    </>
  }
